import type { ServerResponseCommonType } from '~/types/commons';
import type { StaticPageType } from '~/composables/static-page/types/StaticPageType';

type UseStaticPageType = {
	getStaticPageByUrl:(usr:string) => Promise<StaticPageType | null>;
}

export function useStaticPage ():UseStaticPageType {
  const { $api } = useNuxtApp();

  async function getStaticPageByUrl (url: string):Promise<StaticPageType | null> {
    const res = await $api<ServerResponseCommonType<StaticPageType>>('staticPage/getByUrn', {
      query: { urn: url }
    }).catch((err) => { throw err; });
    return res && res.message === 'Success' ? res.data : null;
  }
  return {
	  getStaticPageByUrl
  };
}
